const { dayjs } = window;
const currentDay = dayjs && dayjs().format('DD');
const currentMonth = dayjs && dayjs().format('MM');
const prevMonth = dayjs && dayjs().subtract(1, 'month').format('MM');
const nextMonth = dayjs && dayjs().add(1, 'month').format('MM');
const currentYear = dayjs && dayjs().format('YYYY');

const events = [
  {
    title: 'Boot Camp',
    start: `${currentYear}-${currentMonth}-01 10:00:00`,
    end: `${currentYear}-${currentMonth}-03 16:00:00`,
    description:
      "Boston Harbor Now in partnership with the Friends of Christopher Columbus Park, the Wharf District Council and the City of Boston is proud to announce the New Year's Eve Midnight Harbor Fireworks! This beloved nearly 40-year old tradition is made possible by the generous support of local waterfront organizations and businesses and the support of the City of Boston and the Office of Mayor Marty Walsh.",
    className: 'bg-soft-success',
    location:
      'Boston Harborwalk, Christopher Columbus Park, </br> Boston, MA 02109, United States',
    organizer: 'Boston Harbor Now',
  },
  {
    title: `Crain's New York Business `,
    start: `${currentYear}-${currentMonth}-11`,
    description: 'Lorem ipsum dolor sit amet, conse ctetur',
    className: 'bg-soft-primary',
  },
  {
    title: 'Conference',
    start: `${currentYear}-${currentMonth}-${currentDay}`,
    description: 'Lorem ipsum dolor eius mod tempor labore',
    className: 'bg-soft-success',
    allDay: true,
    schedules: [
      {
        title: 'Reporting',
        start: `${currentYear}-${currentMonth}-${currentDay} 11:00:00`,
        description: 'Lorem ipsum dolor eius mod tempor labore',
        className: 'event-bg-soft-success',
      },
      {
        title: 'Lunch',
        start: `${currentYear}-${currentMonth}-${currentDay} 14:00:00`,
        description: 'Lorem ipsum dolor eius mod tempor labore',
        className: 'event-bg-soft-success',
      },
      {
        title: 'Contest',
        start: `${currentYear}-${currentMonth}-${currentDay} 16:00:00`,
        description: 'Lorem ipsum dolor eius mod tempor labore',
        className: 'event-bg-soft-success',
      },
      {
        title: 'Dinner',
        start: `${currentYear}-${currentMonth}-${currentDay} 22:00:00`,
        description: 'Lorem ipsum dolor eius mod tempor labore',
        className: 'event-bg-soft-success',
      },
    ],
  },
  {
    title: `ICT Expo ${currentYear} - Product Release`,
    start: `${currentYear}-${currentMonth}-16 10:00:00`,
    description: 'Lorem ipsum dolor sit tempor inci',
    end: `${currentYear}-${currentMonth}-18 16:00:00`,
    className: 'bg-soft-warning',
  },
  {
    title: 'Meeting',
    start: `${currentYear}-${currentMonth}-07 10:00:00`,
    description: 'Lorem ipsum dolor eiu idunt ut labore',
  },
  {
    title: 'Meeting',
    start: `${currentYear}-${currentMonth}-14 10:00:00`,
    description: 'Lorem ipsum dolor eiu idunt ut labore',
  },
  {
    title: 'Event With Url',
    start: `${currentYear}-${currentMonth}-23`,
    description: 'Lorem ipsum dolor sit amet, ut labore',
    className: 'bg-soft-success',
    url: 'http://google.com',
  },
  {
    title: 'Meeting',
    start: `${currentYear}-${currentMonth}-26`,
    description: 'Lorem ipsum dolor sit amet, ut labore',
    className: 'bg-soft-danger',
  },
  {
    title: 'Birthday Party',
    start: `${currentYear}-${nextMonth}-05`,
    description: 'Lorem ipsum dolor sit amet, scing',
    className: 'bg-soft-primary',
  },
  {
    title: 'Click for Google',
    url: 'http://google.com/',
    start: `${currentYear}-${prevMonth}-10`,
    description: 'Lorem ipsum dolor sit amet, labore',
    className: 'bg-soft-primary',
  },
];

export default events;
